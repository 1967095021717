export const autoblur = node => {

	const click = e => {
		e.target.closest('button').blur();
	};

	node.addEventListener('click', click);

	return {
		destroy() {
			node.removeEventListener('click', click);
		}
	};

};