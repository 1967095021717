<script>

	// Adapted from the regular signup app
	// for use on the main AZN marketing site

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	import isEmail from 'validator/es/lib/isEmail';

	import { postServerData } from './prelude.js';
	import { autoblur } from './autoblur.js';

	import BigTick from './BigTick.svelte';

	let mounted = false;

	let formHeight;
	let successHeight;

	let container;

	let firstname;
	let lastname;
	let email;

	let staging = false;
	let submitting = false;
	let submitted = false;
	let success = false;
	let confirmed = false;
	let oops = false;
	let showHeading = true;

	let displayLang = 'EN'; // guess we can keep this just in case

	let problems = {
		'firstname': false,
		'lastname': false,
		'email': false
	}

	const labels = {
		'firstname': {
			'EN': 'Your first name'
		},
		'lastname': {
			'EN': 'Your last name'
		},
		'email': {
			'EN': 'Your email address'
		},
		'submit': {
			'EN': 'Impress me'
		},
		'thanks': {
			'EN': 'Great wisdom will soon be yours.'
		},
		'confirm': {
			'EN': 'Please check your email inbox to confirm your subscription.'
		},
		'confirmedThanks': {
			'EN': 'Thank you.'
		},
		'confirmed': {
			'EN': 'Your subscription has been confirmed successfully.'
		},
		'oops': {
			'EN': 'Sorry, we couldn’t confirm this subscription.'
		},
		'ok': {
			'EN': 'OK'
		}
	};

	function clearProblem(e) {
		problems[e.target.name] = false;
	}

	async function handleSubmit() {

		if (!firstname) {
			problems.firstname = true;
		}

		if (!lastname) {
			problems.lastname = true;
		}

		if (!email || !isEmail(email)) {
			problems.email = true;
		}

		if (!problems.firstname && !problems.lastname && !problems.email) {

			successHeight = formHeight;

			submitting = true;

			const response = await postServerData('marketing/signup', {
				firstname: firstname,
				lastname: lastname,
				email: email,
				dataStatus: staging ? 'staging' : 'published',
			});

			if (response) {
				submitted = true;
				setTimeout(() => {
					success = true;
				}, 300);
			}

			submitting = false;

		}

	}

	$: if (successHeight) {
		setContainerHeight();
	}

	function setContainerHeight() {
		container.setAttribute('style', '--successHeight:' + successHeight + ';');
	}

	function closeDialog() {
		confirmed = false;
		oops = false;
	}

	onMount(async () => {

		container = document.getElementById('newsletter');

		staging = container.dataset.staging ? true : false;

		mounted = true;

		if (window.location.pathname.endsWith('subscribe')) {
			showHeading = false;
		}

		let hash = location.hash;
		let hashItems = hash.split('/');

		if ((hashItems[1] == 'azn') && (hashItems[2] == 's') && (hashItems[3])) {

			container.scrollIntoView();

			const response = await postServerData('marketing/signup/confirm', {
				token: hashItems[3]
			});

			if (response && response.status && response.status == 'success') {
				confirmed = true;
			} else {
				oops = true;
			}

		}

	});

</script>

<style>
	:global(#newsletter) {
		min-height: calc(1px * var(--successHeight));
	}

	h2.heading {
		font-size: 1.5rem;
		line-height: 1.2;
	}

	form {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: center;
		gap: 1rem;
	}
	form > p {
		flex: 1 1 100%;
		position: relative;
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		background: #fff;
		border-radius: 3px;
		height: calc(1.4rem + clamp(16px, 1em, 1em));
	}

	form > p.f_submit {
		background: transparent;
	}

	form p label,
	form p input,
	form p button {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0.7rem 1em;
		vertical-align: middle;
		border: 0;
		margin: 0;
		font-size: 1em;
		font-size: clamp(16px, 1em, 1em);
		line-height: clamp(16px, 1em, 1em);
		border-radius: 3px;
	}

	@media screen and (min-width:800px) {
		form > p {
			flex: 1 1 50%;
		}
		form > p.f_submit {
			flex: 0 0 auto;
		}
		form > p.f_submit button {
			position: static;
		}
	}

	@media screen and (min-width:1000px) {
		form {
			flex-wrap: nowrap;
		}
		form > p {
			flex: 1 1 auto;
			height: calc(1.4rem + 0.875rem);
		}
		form > p.email {
			flex: 0 0 35%;
		}
		form p label,
		form p input,
		form p button {
			font-size: 0.875rem;
			line-height: 0.875rem;
		}
	}

	form p label,
	form p input {
		background: transparent;
		font: Inter, sans-serif;
		color: #101820;
	}

	form input:focus {
		/*box-shadow: 0 0 0 2px #e6007e;*/
		box-shadow: 0 0 0 2px #2b275f, 0 0 0 3px #e6007e;
	}

	/*form button:hover,
	form button:active,
	form button:focus {
		box-shadow: none;
		background: #ce0071;
	}*/

	form p.problem input {
		padding-right: 2.4em;
	}

	form p label {
		opacity: 0.6;
		transition: opacity 0.2s ease-out;
	}
	form p label.hasContent {
		opacity: 0;
	}
	form p input:focus {
		outline: none;
	}
	/*form p button {
		border: 1px solid transparent;
		background: #e5077e;
		color: #fff;
	}*/
	form p svg {
		position: absolute;
		top: 50%;
		right: 0.5em;
		width: 1em;
		height: 1em;
		animation: pulse 0.3s ease-out both;
	}
	form svg path {
		fill: #e2261e;
	}

	@keyframes pulse {
	  0% {
	    transform: translateY(-50%) scale(1);
	  }
	  40% {
	    transform: translateY(-50%) scale(1.2);
	  }
	  100% {
	    transform: translateY(-50%) scale(1);
	  }
	}

	.success {
		display: grid;
		place-content: center;
	}
	.success > div {
		display: flex;
		/*align-items: center;*/
	}
	.success p {
		padding-left: 1em;
		font-size: 1em;
		color: #fff;
	}

	.confirmbox {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 90000;
		display: grid;
		place-content: center;
	}
	.confirmbox:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		opacity: 0.95;
	}
	.confirmbox > div {
		position: relative;
		background: #fff;
		box-shadow: 0 4px 12px -2px rgba(0,0,0,0.6);
		padding: 2em;
		text-align: center;
		font-size: 1em;
		color: #101820;
		max-width: 90vw;
		box-sizing: border-box;
	}
	.confirmbox p {
		margin: 2em 0 0 0;
	}
	/*.confirmbox button {
		background: #e6007e;
		color: #fff;
		box-sizing: border-box;
		padding: 0.5em 1em;
		line-height: 1em;
		vertical-align: middle;
		margin: 0;
		font-size: 1em;
	}
	form button:hover,
	form button:focus,
	.confirmbox button:hover,
	.confirmbox button:focus {
		outline: none;
		background: #0c2a42;
		color: #fff;
	}*/
	.confirmbox .oops svg {
		width: 3em;
		height: 3em;
	}
	.confirmbox .oops svg path {
		fill: #e2261e;
	}
</style>

{#if mounted}

	{#if success}
		<div class="success"
			transition:fade|local={{duration:200}}
			style="min-height:{successHeight}px"
		>
			<div>
				<BigTick/>
				<p>
					<strong>{labels.thanks[displayLang]}</strong>
					<span>{labels.confirm[displayLang]}</span>
				</p>
			</div>
		</div>
	{/if}
	{#if !submitted}
		<div bind:clientHeight={formHeight}>
			{#if showHeading}<h2 class="heading"><strong>Most newsletters are terrible. Ours, on the other hand …</strong></h2>{/if}
			<form
				on:submit|preventDefault={handleSubmit}
				transition:fade|local={{duration:200}}
				disabled={submitting}
			>
				<p class="firstname" class:problem={problems.firstname}>
					<label for="aznsubmit_firstname" class:hasContent={firstname != undefined}>
						{labels.firstname[displayLang]}
					</label>
					<input
						type="text"
						name="firstname"
						id="aznsubmit_firstname"
						bind:value={firstname}
						autocomplete="given-name"
						on:focus={clearProblem}
					/>
					{#if problems.firstname}
						<svg viewBox="0 0 56 56"><path d="M56 28c0 15.464-12.536 28-28 28s-28-12.536-28-28 12.536-28 28-28 28 12.536 28 28zm-28 19.89c2.455 0 4.583-2.053 4.603-4.604-.02-2.513-2.148-4.564-4.603-4.564-2.532 0-4.623 2.052-4.604 4.564-.02 2.551 2.071 4.604 4.604 4.604zm4.257-39.78h-8.535l.768 26.505h7.019l.748-26.505z"/></svg>
					{/if}
				</p>
				<p class="lastname" class:problem={problems.lastname}>
					<label for="aznsubmit_lastname" class:hasContent={lastname != undefined}>
						{labels.lastname[displayLang]}
					</label>
					<input
						type="text"
						name="lastname"
						id="aznsubmit_lastname" bind:value={lastname}
						autocomplete="family-name"
						on:focus={clearProblem}
					/>
					{#if problems.lastname}
						<svg viewBox="0 0 56 56"><path d="M56 28c0 15.464-12.536 28-28 28s-28-12.536-28-28 12.536-28 28-28 28 12.536 28 28zm-28 19.89c2.455 0 4.583-2.053 4.603-4.604-.02-2.513-2.148-4.564-4.603-4.564-2.532 0-4.623 2.052-4.604 4.564-.02 2.551 2.071 4.604 4.604 4.604zm4.257-39.78h-8.535l.768 26.505h7.019l.748-26.505z"/></svg>
					{/if}
				</p>
				<p class="email" class:problem={problems.email}>
					<label for="aznsubmit_email" class:hasContent={email != undefined}>
						{labels.email[displayLang]}
					</label>
					<input
						type="text"
						name="email"
						id="aznsubmit_email"
						bind:value={email}
						inputmode="email"
						on:focus={clearProblem}
					/>
					{#if problems.email}
						<svg viewBox="0 0 56 56"><path d="M56 28c0 15.464-12.536 28-28 28s-28-12.536-28-28 12.536-28 28-28 28 12.536 28 28zm-28 19.89c2.455 0 4.583-2.053 4.603-4.604-.02-2.513-2.148-4.564-4.603-4.564-2.532 0-4.623 2.052-4.604 4.564-.02 2.551 2.071 4.604 4.604 4.604zm4.257-39.78h-8.535l.768 26.505h7.019l.748-26.505z"/></svg>
					{/if}
				</p>
				<p class="f_submit">
					<button type="submit" use:autoblur>
						{labels.submit[displayLang]}
					</button>
				</p>
			</form>
		</div>
	{/if}

	{#if confirmed}
		<div class="confirmbox"
			on:click|stopPropagation={closeDialog}
			in:fade={{delay:400,duration:600}}
			out:fade={{duration:200}}
		>
			<div>
				<BigTick big={true} lineColor="#e5077e"/>
				<p>
					<strong>{labels.confirmedThanks[displayLang]}</strong>
					<span>{labels.confirmed[displayLang]}</span>
				</p>
				<p class="f_submit">
					<button class="signup_submit" type="button" on:click|stopPropagation={closeDialog} use:autoblur>
						{labels.ok[displayLang]}
					</button>
				</p>
			</div>
		</div>
	{/if}

	{#if oops}
		<div class="confirmbox"
			on:click|stopPropagation={closeDialog}
			transition:fade|local={{duration:200}}
		>
			<div>
				<p class="oops">
					<svg viewBox="0 0 56 56"><path d="M56 28c0 15.464-12.536 28-28 28s-28-12.536-28-28 12.536-28 28-28 28 12.536 28 28zm-28 19.89c2.455 0 4.583-2.053 4.603-4.604-.02-2.513-2.148-4.564-4.603-4.564-2.532 0-4.623 2.052-4.604 4.564-.02 2.551 2.071 4.604 4.604 4.604zm4.257-39.78h-8.535l.768 26.505h7.019l.748-26.505z"/></svg>
				</p>
				<p>
					<span>{labels.oops[displayLang]}</span>
				</p>
				<p class="f_submit">
					<button type="button" on:click|stopPropagation={closeDialog} use:autoblur>
						{labels.ok[displayLang]}
					</button>
				</p>
			</div>
		</div>
	{/if}

{/if}
