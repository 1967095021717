import axios from 'axios';

const prelude = axios.create({
	baseURL: 'https://api.attendzen.io/',
	timeout: 100000,
	withCredentials: false
});

export const postServerData = async (endpoint, params) => {

	let formData = new FormData();

	if (params) {
		for (let [key, value] of Object.entries(params)) {
			formData.append(key, value);
		}
	}

	try {

		const response = await prelude({
			url: endpoint,
			method: "POST",
			data: formData
		});

		return response.data;

	} catch (err) {

		// console.log(err.toJSON());
		// console.log(err.response.status);

		return null;

	}

}