<script>

	import { onMount } from 'svelte';
	import { draw,fade } from 'svelte/transition';
	import { quadIn } from 'svelte/easing';

	export let big = false;
	export let lineColor = '#ffffff';

	let mounted = false;

	onMount(async () => {
		mounted = true;
	});

</script>

<style>
	div {
		text-align: center;
		padding: 0;
		overflow: visible;
	}
	svg {
		width: 1.6em;
		height: 1.6em;
		overflow: visible;
	}
	path {
		stroke: var(--lineColor);
		fill: transparent;
		stroke-width: 3px;
		stroke-linejoin:round;
	}
	.complete {
		stroke-linecap: round;
	}
	.big svg {
		width: 3em;
		height: 3em;
	}
</style>

{#if mounted}
	<div class:big style="--lineColor:{lineColor}">
		<svg viewBox="0 0 80 80">
			<path in:draw={{delay:900, duration: 400}} d="M61.9,9C55.7,4.6,48.2,2,40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38c0-4-0.6-7.8-1.7-11.4"/>
			<path in:draw={{delay:800, duration: 500, easing: quadIn}} d="M22.4,43.5L33,54.1c0.2,0.2,0.5,0.2,0.8,0c1-1,44.3-44.3,44.3-44.3"/>
			<path class="complete" in:fade={{delay:1100, duration: 300}} d="M61.9,9C55.7,4.6,48.2,2,40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38c0-4-0.6-7.8-1.7-11.4"/>
			<path class="complete" in:fade={{delay:1100, duration: 300, easing: quadIn}} d="M22.4,43.5L33,54.1c0.2,0.2,0.5,0.2,0.8,0c1-1,44.3-44.3,44.3-44.3"/>
		</svg>
	</div>
{/if}